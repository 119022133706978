let faq = {

    init : () => {

        var J = jQuery;
        var Jd = J(document);

        Jd.off('click', '.tab-btn-faq').on('click', '.tab-btn-faq', function(){

            var $content = J(this).parent().find('.content-faq');
            var $contentico = J(this).parent().find('.fa-plus');


            if ($content.hasClass('content-faq-visible')) {
                $content.removeClass('content-faq-visible');
                $contentico.removeClass('ico-faq-visible');

               // J(this).find('.icon').html('<i class="fas fa-chevron-up"></i>');
            } else {
                $content.addClass('content-faq-visible');
                $contentico.addClass('ico-faq-visible');
               // J(this).find('.icon').html('<i class="fas fa-chevron-down"></i>');
                
            }
        });
    }
}
module.exports = faq;