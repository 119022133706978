"use-strict";
window.addEventListener("load", function() {
    //require('./modules/nav').init();
    //require('./modules/upsell').init();
    //require('./modules/map').init();
    //require('./modules/slider').init();
    //require('./modules/tab').init();
    require('./modules/faq').init();
    require('./modules/modal').init();
    //require('./modules/checkout').init();
});