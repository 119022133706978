let modal = {

    init : () => {

        var J = jQuery;
        var Jd = J(document);

        Jd.off('click', '.bloc-lien-popup > a').on('click', '.bloc-lien-popup > a', function(e){
            console.log('*** MODAL LINK ACTION ***')
            e.preventDefault();
            let href = $(this).attr('href')
            document.cookie = 'pum-133=1; path=/';
            window.location.href = href;
        });
    }
}
module.exports = modal;